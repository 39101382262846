<template>
  <v-container
    id="tutorials"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <v-btn
          color="blue"
          dark
          @click="openDashboard()"
        >
          {{ str['open_new_tab_dashboard'] }}
        </v-btn>
      </v-col>
      <v-col
        v-if="videos && videos.length"
        cols="12"
      >
        <div
          v-for="(video, index) in videos"
          :key="index"
          class="pb-1"
        >
          <base-material-card
            color="secondary"
            class="px-5"
          >
            <template v-slot:heading>
              <div class="display-2 font-weight-light">
                {{ str[video.name] ? str[video.name] : video.name }}
              </div>
            </template>
            <v-card-text>
              <div
                v-if="video.url"
                class="text-center"
              >
                <iframe
                  :width="video.width ? video.width : 560"
                  :height="video.height ? video.height : 315"
                  :src="getVideoUrl(video.url)"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </v-card-text>
          </base-material-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Utils from '@/services/Utils'

  export default {
    data () {
      const user = Utils.getUser()
      return {
        str: window.strings,
        user: user,
        videos: user && user.configurations && user.configurations.tutorials && user.configurations.tutorials.videos ? user.configurations.tutorials.videos : null,
        getVideoUrl: Utils.getYoutubeEmbedUrl,
      }
    },
    methods: {
      openDashboard: function () {
        window.open(window.location.href.split('#')[0], '_blank')
      }
    }
  }
</script>
